import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// import { TokenInterface } from '@de-crmintegration-org/api-interface';
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from 'react-toasts';
import './app.scss';
import { ReactComponent as Logo } from './logo.svg';
import { Navigation } from './nav';
import { dateFormat, brandDisplay } from './util';
import { connect } from 'react-redux';
import { authAction } from './redux/actions/auth';
import { Redirect } from 'react-router-dom';

interface TokenObject {
  _id: string;
  crmUserId: number;
  token: string;
  createdAt: Date;
}

interface IHomeProps {
  errorState: boolean;
  // tokenData: TokenObject[]
  tokenData: any[];
  login?: any;
}
// interface IHomeState {}

class Home extends React.PureComponent<IHomeProps> {
  constructor(props: any, state: any) {
    super(props, state)
    console.log("HOME.TSX PROPS: ", props);

  }
  render() {
    const { tokenData, errorState, login } = this.props;
    if (login) {
      if (errorState) {
        return (
          <>
            <h1>Error handling API.</h1>
            <p>Please take screeshot and send email below. Thanks!</p>
            <a href="mailto:danish@tr360fx.com">
              Send an Email (danish@tr360fx.com)
            </a>
            <p>{JSON.stringify(errorState)}</p>
          </>
        );
      }
      return (
        <div className="app">
          <header className="flex">
            <Logo width="75" height="75" />
            <h1>
              Welcome to <small>Clientzone Push Notifications!</small>
            </h1>
          </header>
          <Navigation />
          <main>
            <h5>For Emoji, Use following site:</h5>
            <a href="https://www.iemoji.com">https://www.iemoji.com</a>
            <h3>
              Registered Token Count:{' '}
              {typeof tokenData !== 'undefined' &&
                Object.keys(tokenData).length}
            </h3>
            <div style={{ overflowX: 'scroll' }}>
              <table style={{ width: 'max-content' }}>
                <thead>
                  <tr className="thead">
                    <th className="row-1 row-no">#</th>
                    <th className="row-3 row-name">Crm User Id</th>
                    <th className="row-4 row-job">Token</th>
                    <th className="row-4 row-job">Application Brand</th>
                    <th className="row-4 row-job">Language</th>
                    <th className="row-5 row-email">Email</th>
                    <th className="row-5 row-email">lastActiveAt</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof tokenData !== 'undefined' &&
                    tokenData.map((tokenObj: any, i: any) => {
                      return (
                        <tr key={i} style={{ textAlign: 'center' }}>
                          <td className="row-1 row-no">{`${i + 1}`}</td>
                          <td className="row-3 row-name">
                            {tokenObj.crmUserId}
                          </td>
                          <td className="row-4 row-job">
                            <CopyToClipboard
                              text={tokenObj.token}
                              onCopy={() => {
                                ToastsStore.success(
                                  `Token of Crm User Id ${tokenObj.crmUserId} \n is Copied in Clipboard`,
                                  3000
                                );
                              }}
                            >
                              <button>Copy in Clipboard</button>
                            </CopyToClipboard>
                          </td>
                          <td className="row-4 row-job">
                            {tokenObj.applicationBrand}
                          </td>
                          <td className="row-4 row-job">
                            {tokenObj.language}
                          </td>
                          <td className="row-5 row-email">{tokenObj.email}</td>
                          <td className="row-5 row-email">
                            {' '}
                            {dateFormat(tokenObj.lastActiveAt)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <ToastsContainer
                position={ToastsContainerPosition.TOP_LEFT}
                // lightBackground
                store={ToastsStore}
              />
            </div>
          </main>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapStateToProps = (state: any) => ({
  // auth
  login: state.auth.login,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,

  null
)(Home);
