// export const SEND_SINGLE_PN =
//   'https://clouderpsys.com/api/pushnotification/sendOnePN';
// export const SEND_ALL_PN =
//   'https://clouderpsys.com/api/pushnotification/sendAllPN';
// export const GET_PN_LOGS =
//   'https://clouderpsys.com/api/pushnotification/getAllFirebaseLogs';
// export const GET_ALL_TOKENS = "https://clouderpsys.com/api/pushnotification";
// export const GET_ALL_TOKENS = 'http://localhost:3333/api/pushnotification/';

export const SEND_SINGLE_PN =
  'https://clientzones-pn.fortexadvisor.com/api/pushnotification/sendOnePN'; //working
export const SEND_ALL_PN =
  // 'http://localhost:3333/api/pushnotification/sendAllPN';
  'https://clientzones-pn.fortexadvisor.com/api/pushnotification/sendAllPN'; //working
export const GET_PN_LOGS =
  'https://clientzones-pn.fortexadvisor.com/api/pushnotification/getAllFirebaseLogs'; //working
export const GET_ALL_TOKENS =
  'https://clientzones-pn.fortexadvisor.com/api/pushnotification/'; //working
