import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './app.scss';

import { ReactComponent as Logo } from './logo.svg';
import { Navigation } from './nav';
import MessageForm from './form';
import { dateFormat } from './util';
import { GET_PN_LOGS } from './api';
import { connect } from 'react-redux';
import { authAction } from './redux/actions/auth';
import { Redirect } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';

interface ISendingSingleMessageProps {
  errorState: boolean;
  login?: any;
}

interface ISendingSingleMessageState {
  [key: string]: any
  // logsObject: [key: any];
  // error: boolean;
  // all: boolean,
  // admin: boolean,
  // fatima: boolean,
  // dogan: boolean,
  // mert: boolean,
  // alper: boolean
}

class Logs extends React.PureComponent<
  ISendingSingleMessageProps,
  ISendingSingleMessageState
  > {
  constructor(props: ISendingSingleMessageProps) {
    super(props);
    this.state = {
      error: false,
      logsObject: {},
      all: false,
      admin: false,
      fatima: false,
      dogan: false,
      mert: false,
      alper: false
    };
  }

  async componentDidMount() {
    // await axios.get(GET_PN_LOGS)
    this.allUsers();
  }

  allUsers = async () => {
    this.setState({
      all: true,
      admin: false,
      fatima: false,
      dogan: false,
      mert: false,
      alper: false
    });
    try {
      const response = await axios.get(GET_PN_LOGS);
      console.log(response.data);
      this.setState({
        error: false,
        logsObject: response.data
      });
    } catch (e) {
      this.setState({
        error: true
      });
    }
  };

  check = (username: string) => {
    if (username === 'admin') {
      this.setState({
        all: false,
        admin: true,
        fatima: false,
        dogan: false,
        mert: false,
        alper: false
      });
    } else if (username === 'dogan') {
      this.setState({
        all: false,
        admin: false,
        fatima: false,
        dogan: true,
        mert: false,
        alper: false
      });
    } else if (username === 'fatima') {
      this.setState({
        all: false,
        admin: false,
        fatima: true,
        dogan: false,
        mert: false,
        alper: false
      });
    } else if (username === 'mert') {
      this.setState({
        all: false,
        admin: false,
        fatima: false,
        dogan: false,
        mert: true,
        alper: false
      });
    } else if (username === 'alper') {
      this.setState({
        all: false,
        admin: false,
        fatima: false,
        dogan: false,
        mert: false,
        alper: true
      });
    }
  };

  usernameHandler = async (username: any) => {
    this.check(username);
    // await axios.get(GET_PN_LOGS)
    try {
      const response = await axios.get(
        `https://clientzones-pn.fortexadvisor.com/api/pushnotification/getLogsByUsername/${username}`
      );
      console.log(response.data);
      this.setState({
        error: false,
        logsObject: response.data
      });
    } catch (e) {
      this.setState({
        error: true
      });
    }
  };

  render() {
    const { error, logsObject } = this.state;
    const { login } = this.props;

    console.log(logsObject);

    let tokenLength =
      typeof logsObject !== 'undefined' && Object.keys(logsObject).length;

    console.log(tokenLength);

    if (!login) {
      return <Redirect to="/" />;
    }
    return (
      <div className="app">
        <header className="flex">
          <Logo width="75" height="75" />
          <h1>
            Send Push Notifications to{' '}
            <small style={{ background: '#CDDC39', color: '#000' }}>
              Single clients!
            </small>
          </h1>
        </header>
        <Navigation />
        <main>
          {error && <h1>Error Retreiving Logs OR No logs found!</h1>}
          {!error && (
            // <h3>Logs Count: {typeof logsObject !== "undefined" && logsLen}</h3>
            <div className="logsContainer">
              {/* All Users */}
              <div>
                <div>
                  <label htmlFor="all" className="label">
                    All Users
                  </label>
                  <span>
                    <input
                      id="all"
                      className="input"
                      type="radio"
                      checked={this.state.all}
                      onChange={this.allUsers}
                    />
                  </span>
                </div>
                {/* admin */}
                <div>
                  <label htmlFor="admin" className="label">
                    Admin
                  </label>
                  <span>
                    <input
                      id="admin"
                      className="input"
                      checked={this.state.admin}
                      type="radio"
                      onChange={() => this.usernameHandler('admin')}
                    />
                  </span>
                </div>
                {/* dogan */}
                <div>
                  <label htmlFor="dogan" className="label">
                    Dogan
                  </label>
                  <span>
                    <input
                      id="dogan"
                      className="input"
                      checked={this.state.dogan}
                      type="radio"
                      onChange={() => this.usernameHandler('dogan')}
                    />
                  </span>
                </div>
                {/* fatima */}
                <div>
                  <label htmlFor="fatima" className="label">
                    Fatima
                  </label>
                  <span>
                    <input
                      id="fatima"
                      className="input"
                      checked={this.state.fatima}
                      type="radio"
                      onChange={() => this.usernameHandler('fatima')}
                    />
                  </span>
                </div>
                {/* mert */}
                <div>
                  <label htmlFor="mert" className="label">
                    Mert
                  </label>
                  <span>
                    <input
                      id="mert"
                      className="input"
                      checked={this.state.mert}
                      type="radio"
                      onChange={() => this.usernameHandler('mert')}
                    />
                  </span>
                </div>
                {/* alper */}
                <div>
                  <label htmlFor="alper" className="label">
                    Alper
                  </label>
                  <span>
                    <input
                      id="alper"
                      className="input"
                      checked={this.state.alper}
                      type="radio"
                      onChange={() => this.usernameHandler('alper')}
                    />
                  </span>
                </div>
              </div>
              {tokenLength <= 0 ? (
                <div className="nothing">Nothing</div>
              ) : (
                  <div
                    style={{
                      // width: '1000px',
                      overflow: 'scroll',
                      height: '900px',
                      border: '2px solid #143055',
                      marginTop: '20px'
                    }}
                  >
                    <table style={{ borderSpacing: 'unset' }}>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th
                            style={{
                              width: '5%'
                            }}
                            className="tableHeading"
                          >
                            Sr.#
                        </th>
                          <th
                            style={{
                              width: '5%'
                            }}
                            className="tableHeading"
                          >
                            Success
                        </th>
                          {/* <th
                          style={{
                            width: '5%'
                          }}
                          className="tableHeading"
                        >
                          Failure
                        </th> */}
                          <th
                            style={{
                              width: '10%'
                            }}
                            className="tableHeading"
                          >
                            Username
                        </th>
                          <th
                            style={{
                              width: '20%'
                            }}
                            className="tableHeading"
                          >
                            Title
                        </th>
                          <th
                            style={{
                              width: '30%'
                            }}
                            className="tableHeading"
                          >
                            Message
                        </th>
                          <th
                            style={{
                              width: '25%'
                            }}
                            className="tableHeading"
                          >
                            Notification Time
                        </th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof logsObject !== 'undefined' &&
                          Object.keys(logsObject)
                            .map((obj: any, i) => {
                              return (
                                <tr
                                  id="trLogs"
                                  key={i}
                                  style={{ textAlign: 'center' }}
                                >
                                  <td id="td">{`${i + 1}`}</td>
                                  <td id="td">
                                    {logsObject[obj]['responseObject'][
                                      'successCount'
                                    ] === 1 ? (
                                        <p
                                          style={{
                                            color: 'green',
                                            fontSize: '25px'
                                          }}
                                        >
                                          {'\u2714'}
                                        </p>
                                      ) : (
                                        <p
                                          style={{ color: 'red', fontSize: '25px' }}
                                        >
                                          {'\u2716'}
                                        </p>
                                      )}
                                  </td>
                                  {/* <td>
                                  {
                                    logsObject[obj]['responseObject'][
                                      'failureCount'
                                    ]
                                  }
                                </td> */}
                                  <td id="td">{logsObject[obj]['username']}</td>
                                  <td id="td">{logsObject[obj]['title']}</td>
                                  <td id="td">
                                    {logsObject[obj]['body'].length > 15
                                      ? `${logsObject[obj]['body'].substring(
                                        0,
                                        40
                                      )}...`
                                      : logsObject[obj]['body']}
                                  </td>
                                  <td id="td">
                                    {new Date(
                                      `${logsObject[obj]['createdAt']}`
                                    ).getDate() +
                                      '-' +
                                      (new Date(
                                        `${logsObject[obj]['createdAt']}`
                                      ).getMonth() +
                                        1) +
                                      '-' +
                                      new Date(
                                        `${logsObject[obj]['createdAt']}`
                                      ).getFullYear() +
                                      ' ' +
                                      new Date(
                                        `${logsObject[obj]['createdAt']}`
                                      ).getHours() +
                                      ':' +
                                      new Date(
                                        `${logsObject[obj]['createdAt']}`
                                      ).getMinutes()}
                                  </td>
                                </tr>
                              );
                            })
                            .reverse()}
                      </tbody>
                    </table>
                  </div>
                )}
              {/* {typeof logsObject !== 'undefined' &&
                Object.keys(logsObject)
                  .map((obj, i) => {
                    return (
                      <div key={i} className="logsBlock">
                        <div className="groups count">
                          <span>#</span>
                          <span>{`${i + 1}`}</span>
                        </div>
                        <div className="groups">
                          <span>_id</span>
                          <span>{logsObject[obj]['_id']}</span>
                        </div>
                        <div className="groups">
                          <span>Results Object</span>
                          <pre>
                            {JSON.stringify(
                              logsObject[obj]['responseObject']['results']
                            )}
                          </pre>
                        </div>
                        <div className="groups fail">
                          <span>Failure Count</span>
                          <span>
                            {logsObject[obj]['responseObject']['failureCount']}
                          </span>
                        </div>
                        <div className="groups success">
                          <span>Success Count</span>
                          <span>
                            {logsObject[obj]['responseObject']['successCount']}
                          </span>
                        </div>
                        <div className="groups">
                          <span>Created At</span>
                          <span>
                            {dateFormat(logsObject[obj]['createdAt'])}
                          </span>
                        </div>
                      </div>
                    );
                  })
                  .reverse()} */}
            </div>
          )}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: { auth: { login: any; }; }) => ({
  // auth
  login: state.auth.login
});

export default connect(
  mapStateToProps,
  {
    authAction
  }
)(Logs);
