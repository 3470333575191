import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './app.scss';

import { ReactComponent as Logo } from './logo.svg';
import { Navigation } from './nav';
import MessageForm from './form';
import { SEND_SINGLE_PN } from './api';
import { connect } from 'react-redux';
import { authAction } from './redux/actions/auth';
import { Redirect } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';

interface ISendingSingleMessageProps {
  errorState: boolean;
  login?: any;
}

interface ISendingSingleMessageState {
  [key: string]: any
}

class SendSingleMessage extends React.PureComponent<
  ISendingSingleMessageProps,
  ISendingSingleMessageState
  > {
  constructor(props: ISendingSingleMessageProps) {
    super(props);
    this.state = {
      singleSuccessStatus: null
    };
  }

  private handleSingleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<string> => {
    // prevent cliking
    e.preventDefault();
    // get post data
    const data = new FormData(e.currentTarget);
    const username = localStorage.getItem('username');
    console.log(username);
    const postData = `firebaseToken=${data.get('token')}&title=${data.get(
      'title'
    )}&body=${data.get('body')}&username=${username}`;
    // rest form
    e.currentTarget.reset();
    console.log(postData);
    // axios
    try {
      const response = await axios.post(SEND_SINGLE_PN, postData);
      const serverResponse = response.data;
      console.log(response.data);
      if (response.data.successCount) {
        // alert("successfully Send!");
        this.setState({ singleSuccessStatus: true });
      } else {
        // alert("There was a problem sending push notification!");
        this.setState({ singleSuccessStatus: false });
      }
      // setErrorState(false);
      // setTokenData(response.data);
      return 'testing';
    } catch (e) {
      // setErrorState(e);
      console.log(e);
      return 'not working';
    }
  };

  render() {
    const { login } = this.props;

    if (!login) {
      return <Redirect to="/" />;
    }
    return (
      <div className="app">
        <header className="flex">
          <Logo width="75" height="75" />
          <h1>
            Send Push Notifications to{' '}
            <small style={{ background: '#CDDC39', color: '#000' }}>
              Single clients!
            </small>
          </h1>
        </header>
        <Navigation />
        <main>
          <h1>Construct Push Message</h1>
          <MessageForm
            single={true}
            outHandler={this.handleSingleSubmit.bind(this)}
            singleSuccessStatus={this.state.singleSuccessStatus}
          />
          <pre>Status: {this.props.errorState ? 'ERROR' : 'Ready'}</pre>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  // auth
  login: state.auth.login
});

export default connect(
  mapStateToProps,
  {
    authAction
  }
)(SendSingleMessage);
