export function dateFormat (date: Date) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  const current = new Date()
  // const datee = new Date(date);
  // const month = date.getMonth();
  // const day = date.getDate();
  // const monthString = month >= 10 ? month : `0${month}`;
  // const dayString = day >= 10 ? day : `0${day}`;
  // return `${date.getFullYear()}-${monthString}-${dayString}`;
  // return datee.toTimeString();
  return current.toLocaleDateString('en-US', options)
}

export function brandDisplay (crmUserId: string): string {
  const safeStr = String(crmUserId)
  // if(safeStr.substr(0,3) === "558") {
  // return "ICB Capital (Demo)";
  // } else if(safeStr.substr(0,3) === "391") {
  // return "ICB Capital (Real)";
  // if(safeStr.substr(0,3) === "131") {
  if (safeStr.substr(0, 3) === '558' || safeStr.substr(0, 3) === '391') {
    return 'ICB Capital'
  } else {
    return '360fx'
  }
}
