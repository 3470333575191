import React from 'react';
import axios from 'axios';
import bluebird from 'bluebird';
import './app.scss';

import { Route, Link } from 'react-router-dom';
import Home from './home';
import SendMessage from './sendAllMessage';
import SendSingleMessage from './sendSingleMessage';
import Logs from './Logs';
import { GET_ALL_TOKENS } from './api';
import Login from './components/auth/Login';
import { connect } from 'react-redux';
import { User } from './redux/reducers/auth';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

interface IAppProps {
  user?: User,
  login: boolean
}
interface IAppState { }

class App extends React.Component<IAppProps, any> {
  constructor(props: IAppProps, state: any) {
    super(props, state);
    console.log("APP.TSX PROPS: ", props);

    this.state = {
      tokenData: [],
      errorState: false
    };
  }

  // const [tokenData, setTokenData] = useState([]);
  // const [errorState, setErrorState] = useState(false);

  // console.log(GET_ALL_TOKENS);
  fetchData = async () => {
    console.log("VERSION === 1");
    
    try {
      // let tokenArray = [];
      await axios({
        method: 'get',
        url: GET_ALL_TOKENS,
        headers: {},
        timeout: 999999999999999
      })
        .then(async response => {
          const result = response.data;
          // console.log("@AXIOS RESULT ", Object.values(result));

          // const finalClients: any[] = [];
          // await bluebird.map(Object.values(result), async (client: any) => {
          //   // console.log(client);
          //   await axios.post("https://securecrm.icb.capital/rest/users?version=1.0.0", {
          //     email: client.email
          //   }, {
          //     headers: {
          //       Authorization: "Bearer " + "YTIyNjc5ZDE2MjNlOWM1YTlhOTVhNjZkMjgxMjc5NTgzNjA3Y2NjYmRiYTFmMjBmY2UxMzZhMTM4NGU4OTRkZg",
          //       "Content-Type": "application/json",
          //     }
          //   }).then((res) => {


          //     try {
          //       // console.log(client.email);

          //       finalClients.push({ ...client, language: res.data[0].language })
          //     } catch (error) {
          //       console.log(client.email);
          //       // finalClients.push({ ...client, language: "tr" })

          //     }

          //   })

          // }, { concurrency: 1000 }).then(() => {
          //   console.log("@DONE MAPPING: ", finalClients);

          // })

          this.setState({ tokenData: result });
        })
        // .then(response => console.log(response))
        .catch((error) => {
          this.setState({ errorState: false });
        });
    } catch (e) {
      this.setState({ errorState: e });
    }
  };
  componentDidMount() {
    this.fetchData();
  }

  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./app.scss file.
   */
  render() {
    let { tokenData, errorState } = this.state;
    // console.log(tokenData.slice(0, 1));

    if (this.props.user) {
      tokenData = tokenData.filter((tokenData: any) => this.props.user!.allowed_users.includes(tokenData.email.toLowerCase()));

    }

    return (
      <>
        <Route path="/" exact render={() => <Login />} />

        <Route
          path="/home"
          exact
          render={() => <Home errorState={errorState} tokenData={tokenData} />}
        />
        <Route
          path="/send-all-messages"
          exact
          render={() => (
            <SendMessage errorState={errorState} tokenData={tokenData} />
          )}
        />
        <Route
          path="/send-single-messages"
          exact
          render={() => <SendSingleMessage errorState={errorState} />}
        />
        <Route
          path="/logs"
          exact
          render={() => <Logs errorState={errorState} />}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  // auth
  login: state.auth.login,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  null
)(App);
