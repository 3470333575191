import { AllowedUser, Authority, User } from "../reducers/auth";
import { SET_USER_TRY, SET_USER_SUCCESS, SET_USER_FAIL, SET_OTHER_USER_SUCCESS } from "./types"

export const authAction = (username: string, password: string) => (dispatch: (arg0: { type: string; payload: boolean | { login: boolean, user: User }; }) => void) => {
    dispatch({ type: SET_USER_TRY, payload: false });

    if (username === "admin" && password === "x-YT#Fz4n!H") {
        dispatch({ type: SET_USER_SUCCESS, payload: true });
        localStorage.setItem('username', username);
    } else if (username === "fatima" && password === "phHc679$+R^") {
        dispatch({ type: SET_USER_SUCCESS, payload: true });
        localStorage.setItem('username', username);
    } else if (username === "alper" && password === "6t^52xdW9!6") {
        dispatch({ type: SET_USER_SUCCESS, payload: true });
        localStorage.setItem('username', username);
    } else if (username === "mert" && password === "t+7HF@UVsa_") {
        dispatch({ type: SET_USER_SUCCESS, payload: true });
        localStorage.setItem('username', username);
    } else if (username === "dogan" && password === "David1945!") {
        dispatch({ type: SET_USER_SUCCESS, payload: true });
        localStorage.setItem('username', username);
    } else if (username === "other" && password === "555555") {
        const thisUser = new User("other", Authority.OTHERS, User.PREDEFINED_ALLOWED_USERS_1);
        dispatch({ type: SET_OTHER_USER_SUCCESS, payload: { login: true, user: thisUser } });
        localStorage.setItem('username', username);
    } else {
        dispatch({ type: SET_USER_FAIL, payload: false });
    }
}
