import React, {useState, useEffect}  from 'react';
import './app.scss';

interface IMessageFormProps {
	single: boolean,
	outHandler: (formData: any) => React.ReactNode;
	// handleTyping(event: React.FormEvent<HTMLInputElement>): void;
	singleSuccessStatus: boolean
}

const MessageForm: React.FunctionComponent<IMessageFormProps> = ({ single, outHandler, singleSuccessStatus }) => {
	if(single) {
		return (
			<>
				{singleSuccessStatus && (<div className="successAlert">
					<h3>Success!</h3>
					<p>Single Push Notification to the device is sent!</p>
				</div>)}
				<form onSubmit={outHandler}>
					<div className="group">
						<input name="title" type="text" required />
						<span className="highlight"></span>
						<span className="bar"></span>
						<label>Notification title</label>
					</div>
					<div className="group">
						<input name="token" type="text" required />
						<span className="highlight"></span>
						<span className="bar"></span>
						<label>Token</label>
					</div>
					<div className="group">
						<textarea name="body" required rows={10} placeholder="Notification text" />
						<span className="highlight"></span>
						<span className="bar"></span>
						{/* <label>Notification text</label> */}
					</div>
					<div className="group-end">
						<button className="sendButton">Single Client send Push Notifications</button>
					</div>
				</form>
			</>
		);
	}
	return (
		<>
			{singleSuccessStatus && (<div className="successAlert">
				<h3>Success!</h3>
				<p>Successfully Push Notification is Queued!</p>
			</div>)}
			<form onSubmit={outHandler}>
				<div className="group">
					<input name="title" type="text" required />
					<span className="highlight"></span>
					<span className="bar"></span>
					<label>Notification title</label>
				</div>
				<div className="group">
					<textarea name="body" required rows={10} placeholder="Notification text" />
					<span className="highlight"></span>
					<span className="bar"></span>
					{/* <label>Notification text</label> */}
				</div>
				<div className="group-end">
					<button className="sendButton">Send Push Notifications to All Clients</button>
				</div>
			</form>
		</>
	);
};

export default MessageForm;