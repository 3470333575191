import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import _chunk from 'lodash.chunk';
import _filter from 'lodash.filter';
import _partition from 'lodash.partition';
// import { TokenInterface } from '@de-crmintegration-org/api-interface';
import './app.scss';
import { ReactComponent as Logo } from './logo.svg';
import { Navigation } from './nav';
import MessageForm from './form';
import { SEND_ALL_PN } from './api';
import { connect } from 'react-redux';
import { authAction } from './redux/actions/auth';
import qs from "qs";

interface ISendingMessageProps {
  errorState: boolean;
  tokenData: any[];
  login?: any;
}
interface ISendingMessageState {
  [key: string]: any
}

class SendMessage extends React.PureComponent<
  ISendingMessageProps,
  ISendingMessageState
  > {
  constructor(props: ISendingMessageProps) {
    super(props);
    this.state = {
      allTokens: null,
      singleSuccessStatus: null,
      brand: '',
      icbCapitalTokens: null,
      traderLoginZoneTokens: null,
      language: '',
      traderLoginZoneTokensV2: [],
      icbCapitalTokensV2: [],
    };
    this.brandSelector = this.brandSelector.bind(this);
  }

  async componentDidMount() {
    // console.log(Object.keys(this.props.tokenData).length);
    /* const filteredTokens = _.map(this.props.tokenData, _.partial(_.pick, _, ['token']) );
    const tokensArray = _.map(filteredTokens, 'token');
    const tokenCunks = _.chunk(tokensArray, 1000);
    this.setState({
      allTokens: tokenCunks
    }); */

    console.log(this.props);


  }

  /**
   * Create chunk according to the brand
   */
  private chunker(brand: string): void {
    // const filteredTokens = _.map(this.props.tokenData, _.partial(_.pick, _, ['token']) );
    let filteredTokens;
    if (brand === 'icbCapital') {
      filteredTokens = _.filter(this.props.tokenData, function (singleObj) {
        return (
          String(singleObj.crmUserId).substr(0, 3) === '558' ||
          String(singleObj.crmUserId).substr(0, 3) === '391'
        );
      });
    } else {
      filteredTokens = _.filter(this.props.tokenData, function (singleObj) {
        return (
          String(singleObj.crmUserId).substr(0, 3) !== '558' ||
          String(singleObj.crmUserId).substr(0, 3) !== '391'
        );
      });
    }
    console.log(filteredTokens);
    const tokensArray = _.map(filteredTokens, 'token');
    const tokenCunks = _.chunk(tokensArray, 1000);
    console.log(tokenCunks);

    this.setState({
      allTokens: tokenCunks
    });
  }

  private brandSelector(brand: string): void {
    // this.setState({ brand }, () => {
    //   this.chunker(brand);
    // });

    this.setState({ brand });

    let traderLoginZoneTokens: any[] = [];
    let icbCapitalTokens: any[] = [];

    if (brand === 'traderLoginZone') {
      this.props.tokenData.map(result => {
        if (result.applicationBrand === 'traderLoginZone') {
          // console.log(result.token);
          // console.log("RESULT TOKENS: (TLZ) ", result.token);
          traderLoginZoneTokens.push(result);
        }
      });
      this.setState({ traderLoginZoneTokens });
    } else if (brand === 'icbCapital') {
      this.props.tokenData.map(result => {
        if (result.applicationBrand === 'icbCapital' || result.applicationBrand === 'avsBrokers') {
          // console.log("RESULT TOKENS: (ICB) ", result.token);
          icbCapitalTokens.push(result);
        }
      });
      this.setState({ icbCapitalTokens });
    }

    // console.log(icbCapitalTokens);

  }

  LanguageSelector = (language: string): void => {

    if (this.state.brand.length === 0) {
      alert('Please select brand.'); return;
    }

    this.setState({ language });

    let { traderLoginZoneTokens, icbCapitalTokens, brand } = this.state;

    let traderLoginZoneTokensV2: any[] = [];
    let icbCapitalTokensV2: any[] = [];

    if (language === 'tr') {
      if (brand === "icbCapital") {
        if (!icbCapitalTokens) return;
        icbCapitalTokensV2 = icbCapitalTokens.filter((client: any) => client.language && client.language === language);
      }
      else if (brand === "traderLoginZone") {
        if (!traderLoginZoneTokens) return;
        traderLoginZoneTokensV2 = traderLoginZoneTokens.filter((client: any) => client.language && client.language === language)
      }
    } else if (language === 'en') {
      if (brand === "icbCapital") {
        if (!icbCapitalTokens) return;
        icbCapitalTokensV2 = icbCapitalTokens.filter((client: any) => client.language && client.language === language)
      }
      else if (brand === "traderLoginZone") {
        if (!traderLoginZoneTokens) return;
        traderLoginZoneTokensV2 = traderLoginZoneTokens.filter((client: any) => client.language && client.language === language)
      }
    }
    else if (language === "all") {
      if (brand === "icbCapital") {
        icbCapitalTokensV2 = icbCapitalTokens;
      }
      else if (brand === "traderLoginZone") {
        traderLoginZoneTokensV2 = traderLoginZoneTokens;
      }
    }

    const t: any[] = [];
    const r: any[] = [];

    traderLoginZoneTokensV2.map(c => t.push(c.token));
    icbCapitalTokensV2.map(c => r.push(c.token));

    if (brand === 'icbCapital') {
      if (language === 'all') {
        console.log("ICB ALL: ", r.length);

      }
      if (language === 'en') {
        console.log("ICB EN: ", r.length);

      }
      if (language === 'tr') {
        console.log("ICB TR: ", r.length);

      }
    }
    else {
      if (language === 'all') {
        console.log("TLZ ALL: ", t.length);

      }
      if (language === 'en') {
        console.log("TLZ EN: ", t.length);

      }
      if (language === 'tr') {
        console.log("TLZ TR: ", t.length);

      }
    }

    this.setState({ traderLoginZoneTokensV2: t, icbCapitalTokensV2: r })

  }

  private handleSingleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    e.preventDefault();
    let { brand, icbCapitalTokensV2, traderLoginZoneTokensV2, language } = this.state;
    const username = localStorage.getItem('username');
    if (brand.length === 0) {
      alert('Please select brand.');
      return 'Error;';
    }

    if (language.length === 0) {
      alert('Please select language.');
      return 'Error;';
    }

    // console.log(icbCapitalTokensV2, traderLoginZoneTokensV2); return;

    // const bodyData = qs.stringify({
    //   'okokok': 'okokoko',
    //   '3333': '32323'
    // });

    e.preventDefault();
    // rest form
    // e.currentTarget.reset(); 
    if (brand === 'icbCapital') {
      const data = new FormData(e.currentTarget);

      icbCapitalTokensV2 = icbCapitalTokensV2.reduce(
        function (p: any, c: any) {
          // if the next object's id is not found in the output array
          // push the object into the output array
          if (
            !p.some(function (el: any) {
              return el === c;
            })
          )
            p.push(c);
          return p;
        },
        []
      );

      console.log("RESULT TOKENS: (ICB) ", icbCapitalTokensV2);

      const awaitSendBatch = async () => {
        for (let i = 0; i < icbCapitalTokensV2.length; i += 500) {
          console.log("SENDING TO BATCH: ", i);
          console.log("ARRAY BATCH: ", icbCapitalTokensV2.slice(i, i + 500));

          await axios.post(SEND_ALL_PN, JSON.stringify({
            firebaseToken: icbCapitalTokensV2.slice(i, i + 500),
            title: data.get("title"),
            body: data.get("body"),
            username,
          }), {
            headers: {
              'Content-Type': 'application/json'
            },
          }).then((responseData) => {
            console.log(responseData.data);
          })


        }
      };

      await awaitSendBatch();

      return null;

      // const data = new FormData(e.currentTarget);
      for (let i = 0; i <= icbCapitalTokensV2.length; i++) {
        const postData = `firebaseToken=${this.state.icbCapitalTokensV2[i]
          }&title=${data.get('title')}&body=${data.get(
            'body'
          )}&username=${username}`;
        try {
          const response = await axios.post(SEND_ALL_PN, postData);
          if (response.data.successCount) {
            // alert("successfully Send!");
            this.setState({ singleSuccessStatus: true });
          } else {
            // alert("There was a problem sending push notification!");
            this.setState({ singleSuccessStatus: false });
          }
          // console.log('works');
        } catch (e) {
          // setErrorState(e);
          console.log(e);
          return 'not working';
        }
      }
    }
    if (brand === 'traderLoginZone') {
      const data = new FormData(e.currentTarget);

      traderLoginZoneTokensV2 = traderLoginZoneTokensV2.reduce(
        function (p: any, c: any) {
          // if the next object's id is not found in the output array
          // push the object into the output array
          if (
            !p.some(function (el: any) {
              return el === c;
            })
          )
            p.push(c);
          return p;
        },
        []
      );

      console.log("RESULT TOKENS: (TRZ) ", traderLoginZoneTokensV2);


      const awaitSendBatch = async () => {
        for (let i = 0; i < traderLoginZoneTokensV2.length; i += 500) {
          console.log("SENDING TO BATCH: ", i);
          console.log("ARRAY BATCH: ", traderLoginZoneTokensV2.slice(i, i + 500));

          await axios.post(SEND_ALL_PN, JSON.stringify({
            firebaseToken: traderLoginZoneTokensV2.slice(i, i + 500),
            title: data.get("title"),
            body: data.get("body"),
            username,
          }), {
            headers: {
              'Content-Type': 'application/json'
            },
          }).then((responseData) => {
            console.log(responseData.data);
          })


        }
      };

      await awaitSendBatch();

      return null;
      for (let i = 0; i <= traderLoginZoneTokensV2.length; i++) {
        // axios
        const postData = `firebaseToken=${this.state.traderLoginZoneTokensV2[i]
          }&title=${data.get('title')}&body=${data.get(
            'body'
          )}&username=${username}`;

        try {
          const response = await axios.post(SEND_ALL_PN, postData);
          if (response.data.successCount) {
            // alert("successfully Send!");
            this.setState({ singleSuccessStatus: true });
          } else {
            // alert("There was a problem sending push notification!");
            this.setState({ singleSuccessStatus: false });
          }
        } catch (e) {
          // setErrorState(e);
          console.log(e);
          return 'not working';
        }
      }
    }
  };

  render() {
    const { brand, icbCapitalTokens } = this.state;
    const { login } = this.props;
    if (!login) {
      return <Redirect to="/" />;
    }
    return (
      <div className="app">
        <header className="flex">
          <Logo width="75" height="75" />
          <h1>
            Send Push Notifications to{' '}
            <small style={{ background: '#FFF', color: '#000' }}>
              All clients!
            </small>
          </h1>
        </header>
        <Navigation />
        <main>
          <h1>Construct Push Message</h1>
          <div className="group" style={{ marginBottom: '0' }}>
            <input
              style={{ display: 'inline-block' }}
              onChange={brand => this.brandSelector('traderLoginZone')}
              checked={brand === 'traderLoginZone'}
              type="checkbox"
              name="brand"
              placeholder="traderLoginZone"
              value="traderLoginZone"
            />
            <label
              style={{ display: 'inline-block', top: 'inherit' }}
              htmlFor="traderLoginZone"
            >
              Trader Login Zone ({this.state.traderLoginZoneTokens && this.state.traderLoginZoneTokens.length})
            </label>
          </div>
          <div className="group">
            <input
              style={{ display: 'inline-block' }}
              onChange={brand => this.brandSelector('icbCapital')}
              checked={brand === 'icbCapital'}
              type="checkbox"
              name="brand"
              placeholder="ICB Capital"
              value="icbCapital"
            />
            <label
              style={{ display: 'inline-block', top: 'inherit' }}
              htmlFor="icbCapital"
            >
              ICB Capital ({this.state.icbCapitalTokens && this.state.icbCapitalTokens.length})
            </label>
          </div>


          <div className="group" style={{ marginBottom: '0' }}>
            <input
              style={{ display: 'inline-block' }}
              onChange={language => this.LanguageSelector("tr")}
              checked={this.state.language === 'tr'}
              type="checkbox"
              name="language"
              placeholder="traderLoginZone"
              value="traderLoginZone"
            />
            <label
              style={{ display: 'inline-block', top: 'inherit' }}
            >
              Turkish ({this.state.brand === 'traderLoginZone' && this.state.traderLoginZoneTokens && this.state.traderLoginZoneTokensV2.length}
              {this.state.brand === 'icbCapital' && this.state.icbCapitalTokens && this.state.language === 'tr' && this.state.icbCapitalTokensV2.length}
              )
            </label>
          </div>
          <div className="group">
            <input
              style={{ display: 'inline-block' }}
              onChange={language => this.LanguageSelector("en")}
              checked={this.state.language === 'en'}
              type="checkbox"
              name="language"
              placeholder="English"
              value="en"
            />
            <label
              style={{ display: 'inline-block', top: 'inherit' }}
            >
              English ({this.state.brand === 'traderLoginZone' && this.state.traderLoginZoneTokens && this.state.traderLoginZoneTokensV2.length}
              {this.state.brand === 'icbCapital' && this.state.icbCapitalTokens && this.state.language === 'en' && this.state.icbCapitalTokensV2.length}
              )
            </label>
          </div>

          <div className="group" style={{ marginTop: "-45px" }}>
            <input
              style={{ display: 'inline-block' }}
              onChange={language => this.LanguageSelector("all")}
              checked={this.state.language === 'all'}
              type="checkbox"
              name="language"
              placeholder="All"
              value="all"
            />
            <label
              style={{ display: 'inline-block', top: 'inherit' }}
            >
              All ({this.state.brand === 'traderLoginZone' && this.state.traderLoginZoneTokens && this.state.traderLoginZoneTokensV2.length}
              {this.state.brand === 'icbCapital' && this.state.icbCapitalTokens && this.state.language === 'all' && this.state.icbCapitalTokensV2.length}
              )
            </label>
          </div>


          <div style={{ marginBottom: '5rem' }}>
            Selected Brand:{' '}
            {brand !== '' && (
              <span className="brandText">
                {brand === 'icbCapital' && 'Icb Capital'}{' '}
                {brand === 'traderLoginZone' && 'Trader Login Zone'}
              </span>
            )}
            <br />
            {
              this.state.language
            }
          </div>
          <MessageForm
            single={false}
            outHandler={this.handleSingleSubmit.bind(this)}
            singleSuccessStatus={this.state.singleSuccessStatus}
          />
          <pre>Status: {this.props.errorState ? 'ERROR' : 'Ready'}</pre>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  // auth
  login: state.auth.login
});

export default connect(
  mapStateToProps,
  {
    authAction
  }
)(SendMessage);
