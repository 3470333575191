import { SET_USER_TRY, SET_USER_SUCCESS, SET_USER_FAIL, SET_OTHER_USER_SUCCESS } from "../actions/types"

export enum Brands {
    "icbCapital",
    "trBrokers",
}

export enum Authority {
    COMPANY = "COMPANY",
    OTHERS = "OTHERS",
}

export interface AllowedUser {
    email: string,
    // deviceToken: string,
    // brand: Brands,
}

export const AVAILABLE_USERS: Array<string> = ["baris.a@icb.capital", "example@email.com", "hey@email.com"];
// export const AVAILABLE_USERS: Array<string> = ["caner.e@icb.capital", "azra.k@icb.capital"];

export class User {
    username: string;
    email?: string;
    authority: Authority;
    allowed_users: Array<string>;

    static PREDEFINED_ALLOWED_USERS_1: Array<string> = AVAILABLE_USERS;

    constructor(username: string, authority: Authority, allowed_users: Array<string>, email?: string) {
        this.username = username;
        this.email = email;
        this.authority = authority;
        this.allowed_users = allowed_users;
    }
}

export interface AuthActionState {
    login: boolean,
    user?: User
}

const defaultState: AuthActionState = {
    login: false,
}

function auth(state = defaultState, action: { type: string; payload: any }): AuthActionState {
    switch (action.type) {
        case SET_USER_TRY:
            return {
                login: action.payload
            }
        case SET_USER_SUCCESS:
            return {
                login: action.payload
            }
        case SET_OTHER_USER_SUCCESS:
            return {
                ...state,
                login: action.payload.login,
                user: action.payload.user,
            }
        case SET_USER_FAIL:
            return {
                login: action.payload
            }

        default: return state
    }
}

export default auth