import React, { PureComponent } from 'react';
import './login.css';
import { authAction } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from 'react-toasts';

// const Login = props => <SignUpContainer />;

class Login extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props, state);

    this.state = {
      username: '',
      password: '',
      message: 'error'
    };
  }

  componentDidMount() {
    this.props.authAction();
    localStorage.removeItem('username');
  }

  usernameOnChangeHandler = (e: any) => {
    this.setState({ username: e.target.value });
  };
  passwordOnChangeHandler = (e: any) => {
    this.setState({ password: e.target.value });
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (username !== '' && password !== '') {
      this.props.authAction(username, password);

      if (this.props.login === false) {
        ToastsStore.success(`Wrong Credentials`, 3000);
      }
    } else {
      console.log('empty');
    }
  };

  render() {
    const { username, password } = this.state;

    if (this.props.login) {
      return <Redirect to="/home" />;
    }
    return (
      <div id="main">
        <div id="space"></div>
        <div id="signUpContainer">
          <div id="signUpFormContainer">
            <div id="signUpHeader">
              <div id="signUpHeaderTitle">Login</div>
            </div>
            <form id="signUpForm">
              <div className="signUpRow">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={this.usernameOnChangeHandler}
                />
              </div>
              <div className="signUpRow">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={this.passwordOnChangeHandler}
                />
              </div>
              <div className="signUpRow">
                <button type="submit" onClick={this.onSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_LEFT}
          store={ToastsStore}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  // auth
  login: state.auth.login
});

export default connect(
  mapStateToProps,
  {
    authAction
  }
)(Login);
